import { BrowserRouter } from "react-router-dom";

import { ToastProvider } from "context/toast-provider";
import TutorAILms from "features/tutor-ai-lms";
import Toast from "components/toast";


const App = () => {
	return (
		<BrowserRouter>
			<ToastProvider>
				<TutorAILms />
				<Toast />
			</ToastProvider>
		</BrowserRouter>
	);
};

export default App;

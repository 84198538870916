import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

import { useToast } from "../../context/toast-provider";
import { FaCheckCircle, FaExclamationCircle, FaExclamationTriangle, FaInfoCircle } from "react-icons/fa";

import "./style.css";

const toastTypes =
{
	primary: {
		id: 1,
		Icon: FaInfoCircle,
		borderColor: "border-b-brand-primary",
		iconColor: "fill-brand-mid",
	},
	info: {
		id: 2,
		Icon: FaInfoCircle,
		borderColor: "border-b-feedback-info",
		iconColor: "fill-feedback-info",
	},
	error: {
		id: 3,
		Icon: FaExclamationCircle,
		borderColor: "border-b-feedback-error",
		iconColor: "fill-feedback-error",
	},
	warning: {
		id: 4,
		Icon: FaExclamationTriangle,
		borderColor: "border-b-feedback-warning",
		iconColor: "fill-feedback-warning",
	},
	waiting: {
		id: 5,
		Icon: FaExclamationCircle,
		borderColor: "border-b-feedback-waiting",
		iconColor: "fill-feedback-waiting",
	},
	success: {
		id: 6,
		Icon: FaCheckCircle,
		borderColor: "border-b-feedback-success",
		iconColor: "fill-feedback-success",
	},
};

interface ToastProps {
	size?: "md" | "lg";
	slideFrom?: "top" | "right";
	duration?: number;
}


const Toast = ({ size = "md", slideFrom = "right", duration = 3000 }: ToastProps) => {
	const { toast, hideToast } = useToast();
	const toastType = toast?.type ? toastTypes[toast.type] : toastTypes.primary;
	const [exiting, setExiting] = useState(false);

	const slideIn = slideFrom === "right" ? "animate-slide-in-right" : "animate-slide-in-down";
	const slideOut = slideFrom === "right" ? "animate-slide-out-right" : "animate-slide-out-up";

	const closeToast = useCallback(() => {
		setExiting(true);
		setTimeout(() => {
			hideToast();
			setExiting(false);
		}, 500);
	}, [hideToast]);

	// eslint-disable-next-line consistent-return
	useEffect(() => {
		if (toast) {
			const timer = setTimeout(closeToast, duration);

			return () => {
				clearTimeout(timer);
			};
		}
	}, [toast, closeToast, duration]);

	const { Icon } = toastType;

	if (!toast) return null;

	return (
		<div className={
			clsx(
				"fixed top-0 z-[9999] w-full max-w-[666px] space-y-2 p-6 sm:w-auto sm:min-w-[360px]",
				slideFrom === "right" ? "right-0" : "left-1/2 -translate-x-1/2",
			)
		}
		>
			<button
				type="button"
				key={toast.id}
				onClick={closeToast}
				className={clsx(
					"flex w-full items-center rounded border-b-8 bg-greyscale-base text-left shadow",
					size === "md" ? "p-4" : "p-6",
					exiting ? slideOut : slideIn,
					toastType.borderColor,
				)}
			>
				<Icon size={size === "lg" ? 32 : 24} className={clsx(toastType.iconColor, "mr-6 shrink-0")} />

				<p
					className={clsx("font-paragraph", size === "lg" ? "text-label-mobile md:text-label-desktop" : "text-label-1-mobile md:text-label-1-desktop")}
				>
					{toast.label}
				</p>
			</button>
		</div>
	);
};

export default Toast;

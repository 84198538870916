import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TutorAI } from "tutor-ai";

import { useToast } from "context/toast-provider";

import config from "config";

const Loader = () => {
	return (
		<div className="flex h-screen items-center justify-center">
			<div className="size-16 animate-spin rounded-full border-y-4 border-purple-300" />
		</div>
	);
};

const toBase64 = (str: string): string => {
	const encoder = new TextEncoder();
	const bytes = encoder.encode(str);
	const base64String = window.btoa(String.fromCharCode(...bytes));
	return base64String;
};


const TutorAILms = () => {
	const [searchParams] = useSearchParams();
	const userEmail = searchParams.get("email");
	const { showToast } = useToast();

	const [base64Email, setBase64Email] = useState<string | null>(null);


	useEffect(() => {
		if (userEmail) {
			const handledBase64Email = toBase64(userEmail);
			setBase64Email(handledBase64Email);
		}
	}, [userEmail]);

	if (!userEmail || !base64Email) return <Loader />;


	return (
		<TutorAI
			renderInput={(props) => (
				<textarea
					className="max-h-40 min-h-[58px] w-full resize-none rounded-lg border-2 border-brand-secondary-light bg-brand-primary-light
					px-6 py-4 pr-16 font-paragraph !text-paragraph-p1-mobile scrollbar-none focus:border-brand-secondary-light
					focus:ring-0 focus:ring-[transparent] focus-visible:outline-none md:!text-paragraph-p1-desktop"
					placeholder="Scrivi qui il tuo messaggio"
					rows={1}
					{...props}
				/>
			)}
			resumeConversation
			resetConversationButton
			messageMaxLength={50000}
			onError={(error: any) => showToast(error.message, "error")}
			Loader={<Loader />}
			accessToken={base64Email}
			apiUrl={`${config.apiUrl}/conversations`}
			tutorProfilePic={`${config.assetsPath}images/logos/mini.svg`}
		/>
	);
};

export default TutorAILms;
